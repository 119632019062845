<template>
  <v-main class="container-prosker container">
    <!-- form
    <v-card>
      <v-container class="px-15 py-5">
        <v-row class="border-bottom border-primary">
          <v-col class="text-center pt-5">
            <h4 class="font-size-18">{{ $t("selectDateFree") }}</h4>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('monday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('tuesday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('wednesday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('thursday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('friday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('saturday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" cols="12" class="bg-green-calendar p-0">
            <v-checkbox
                v-model="checked"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('sunday')"
                color="teal">
            </v-checkbox>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col md="5" cols="6">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="border-top border-primary mt-10">
          <v-col class="text-center pt-5">
            <h4 class="font-size-18">{{ $t("selectSpecialDateFree") }}</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('date')"
                type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                class="p-0"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
                :label="$t('beginning')"
                type="time"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
                class="p-0"
                :label="$t('end')"
                type="time"
                :hide-details="checkedError.length === 0"
                :error-count="checkedError.length"
                :error-messages="checkedError"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="3">
            <v-btn class="white--text" small block color="grey darken-1">
              <v-icon left> mdi-calendar </v-icon><span class="ms-1">{{ $t("addDate") }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="3">
            <v-btn class="bg-green-prosk white--text" small block>
              <span class="ms-1">{{ $t("save") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>-->
    <v-toolbar flat class="bg-light">
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
        {{ $t("today") }}
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon small> mdi-chevron-left </v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="next">
        <v-icon small> mdi-chevron-right </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <!--<v-calendar
        ref="calendar"
        :events="events"
        event-height="50"
        v-model="calendar.day"
        :type="calendar.type"
        :locale="$i18n.locale"
        @click:event="showEvent"
    >-->
    <v-calendar
      ref="calendar"
      :events="events"
      v-model="calendar.day"
      :type="calendar.type"
      :locale="$i18n.locale"
      color="green"
      event-more-text="texto personalizado"
      @click:more="bigDiv(true)"
      :event-margin-bottom="5"
    >
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <template v-slot:day="{ date, day }">
        <div :class="bigDiv(false)"></div>
      </template>
      <template v-slot:day-body="{ date, week }">
        <div style="background-color: #6a75ca"></div>
      </template>
      <template v-slot:day-label="{ date, day }">
        <div
          :class="daysFree.indexOf(date.toString()) === -1 ? '' : 'bg-danger'"
          class="py-2"
        >
          <button
            :disabled="daysFree.indexOf(date.toString()) !== -1"
            @click="showDay(date)"
          >
            <span>
              {{ day }}
            </span>
          </button>
        </div>
      </template>
    </v-calendar>
    <v-dialog
      v-model="showDayDialog"
      width="500"
      persistent
      @keydown.esc="showDayDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5 bg-prosk-secondary white--text">
          <v-row>
            <v-col cols="6">{{ $t("chooseHour") }}</v-col>
            <v-col cols="6" class="text-prosk">
              <span class="float-right">
                {{ getFormattedDate(calendar.day) }}
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-calendar
              type="day"
              first-time="6"
              interval-count="12"
              hide-header
              interval-minutes="15"
              :locale="$i18n.locale"
            >
              <template v-slot:interval="{ time }">
                <div
                  :class="
                    hoursFree.indexOf(time) === -1
                      ? 'hourFree hourFreeSelected'
                      : 'hourFree'
                  "
                >
                  <button
                    :disabled="hoursFree.indexOf(time) === -1"
                    @click="toggleHourFree(time)"
                  >
                    {{
                      hoursFree.indexOf(time) !== -1
                        ? $t('notAvailable')
                        : $t('available')
                    }}
                  </button>
                </div>
              </template>
            </v-calendar>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="showDayDialog = false"
            outlined
            color="red"
            class="white--text"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn :loading="loading" color="teal" class="white--text">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import moment from 'moment';

export default {
  name: 'Calendar',
  data () {
    return {
      selectedElement: null,
      selectedOpen: false,
      selectedEvent: {},
      loading: false,
      events: [
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 15:00',
          end: '2022-12-18 15:30',
          color: 'red'
        },
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 15:00',
          end: '2022-12-18 15:30',
          color: 'red'
        },
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 15:00',
          end: '2022-12-18 15:30',
          color: 'red'
        },
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 15:00',
          end: '2022-12-18 15:30',
          color: 'red'
        },
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 16:00',
          color: 'blue'
        },
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 17:00',
          end: '2022-12-25 17:00',
          color: 'yellow'
        },
        {
          name: 'Cita para trabajar',
          start: '2022-12-16 17:30',
          color: 'purple'
        }
      ],
      checked: false,
      checkedError: [],
      showDayDialog: false,
      daysFree: ['2022-12-29', '2022-12-15'],
      hoursFree: ['6:30', '8:00'],
      on: false,
      attrs: false,
      calendar: {
        day: '2022-12-01',
        type: 'month',
        title: ''
      }
    };
  },
  methods: {
    getFormattedDate (date) {
      const dateFormatted = moment(date).format('dddd Do');
      return dateFormatted;
    },
    bigDiv (bool) {
      if (bool === true) {
        return 'bigDiv';
      } else {
        return 'smallDiv';
      }
    },
    /* showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    }, */
    toggleDayFree (date) {
      date = date.toString();
      let valueIndex = this.daysFree.indexOf(date);
      if (valueIndex === -1) {
        this.daysFree.push(date);
      } else {
        this.daysFree.splice(valueIndex, 1);
      }
    },
    toggleHourFree (hour) {
      let valueIndex = this.hoursFree.indexOf(hour);
      if (valueIndex === -1) {
        this.hoursFree.push(hour);
      } else {
        this.hoursFree.splice(valueIndex, 1);
      }
    },
    showDay (date) {
      this.calendar.day = date;
      this.showDayDialog = true;
    },
    setToday () {
      this.calendar.day = '';
    },
    prev () {
      this.$refs.calendar.prev();
    },
    next () {
      this.$refs.calendar.next();
    }
  },
  mounted () {
    this.bigDiv(false);
    this.calendar.title = this.$refs.calendar.title;
  }
};
</script>
<style>
.bigDiv {
  height: 500px !important;
}

.smallDiv {
  height: 50px !important;
}

.dayFree {
  background-color: green;
}

.hourFree {
  background-color: gray;
  width: 100%;
  height: 100%;
}

.hourFreeSelected {
  background-color: white;
  color: black;
}
</style>
